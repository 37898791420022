import { Spinner } from "react-bootstrap";
import Moment from "react-moment";
import styled from "styled-components";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useNewMoneyOverwriteLogs from "../hooks/useNewMoneyOverwriteLogs";

const TableHeader = styled.th`
    width: ${props => `${props.width}%`};
    padding-top: 0 !important;
`;

const NewMoneyLogs = ({ newMoneyId }) => {
    const { logs, error, isFetching, refetch } = useNewMoneyOverwriteLogs(newMoneyId);

    if (isFetching)
        return <div className="d-flex py-4 justify-content-center">
            <Spinner animation="border" />
        </div>

    if (error)
        return <ErrorMessageWrapper>
            <ErrorMessage
                error={error}
                message={error.data}
                retryCallback={refetch}
            />
        </ErrorMessageWrapper>;

    return <div className="table-responsive">
        <table className="table m-0">
            <thead>
                <tr>
                    <TableHeader width={60}>Reason</TableHeader>
                    <TableHeader width={25}>Date Overwritten</TableHeader>
                    <TableHeader width={15}>Overwritten By</TableHeader>
                </tr>
            </thead>
            <tbody className="overflow-x-auto border-top-0">
                {logs != null && logs.length > 0
                    ? logs.map((el, index) => (
                        <tr key={index}>
                            <td>{el.overwriteComment}</td>
                            <td><Moment date={el.overwriteDate} format="DD/MM/YYYY @ HH:mm" /></td>
                            <td>{el.overwrittenBy}</td>
                        </tr>
                    ))
                    : <tr>
                        <td colSpan={3}>There are no logs to show.</td>
                    </tr>
                }
            </tbody>
        </table>
    </div>;
}

export default NewMoneyLogs;