import { create } from 'lodash';
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";
import _ from "lodash";

const investmentTransactionApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["investmentTransactions", "investmentTransaction"]
})
.injectEndpoints({
    endpoints: (builder) => ({
        getInvestmentTransaction: builder.query({
            query: ({ investmentTransactionId }) => ({
                url: `api/valuations/investmenttransactions/${investmentTransactionId}`,
                method: 'GET',
            }),
            providesTags: (result, error, arg) => {
                return ['investmentTransaction'];
            }
        }),
        createTransaction: builder.mutation({
            query: (data) => ({
                url: `api/valuations/investmenttransactions/`,
                method: 'POST',
                body: data
            }),
            async onQueryStarted(
                 { masterAccountId},
                { dispatch, queryFulfilled }
            ) {
                try {
                  const { data: createResult } = await queryFulfilled;
                    dispatch(
                        wealthProApi.util.updateQueryData(
                            "searchInvestmentTransactions",
                            { masterAccountId },
                            ({results, pagination}) => {
                                return {
                                    results: results.length > 0 ? [createResult,
                                    ...results] : [createResult],
                                    pagination: {
                                        ...pagination,
                                        totalCount: pagination.totalCount + 1
                                    }
                                }
                        }
                        )
                    );
                } catch {
                    //result.undo();
                }
            },
        }),
        duplicateTransaction: builder.mutation({
            query: ({ investmentTransactionId, masterAccountId }) => ({
                url: `api/valuations/investmenttransactions/duplicate/${investmentTransactionId}`,
                method: 'PUT'
            }),
            async onQueryStarted(
                 { investmentTransactionId, masterAccountId},
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        wealthProApi.util.updateQueryData("searchInvestmentTransactions", { masterAccountId }, ({results, pagination}) => {
                            return {
                                results: [data, ...results],
                                pagination: {
                                    ...pagination,
                                    totalCount: pagination.totalCount + 1
                                }
                            }
                        })
                    );
                } catch {
                    //result.undo();
                }
            },
        }),
        reviseTransaction: builder.mutation({
            query: ({ request }) => ({
                url: `api/valuations/investmenttransactions/revise/`,
                method: 'POST',
                body: request
            }),
            invalidatesTags: ["valuation"]
        }),
        deleteTransaction: builder.mutation({
            query: ({ investmentTransactionId, masterAccountId }) => ({
                url: `api/valuations/investmenttransactions/${investmentTransactionId}`,
                method: 'DELETE',
            }),
            async onQueryStarted(
                 {investmentTransactionId, masterAccountId},
                { dispatch, queryFulfilled }
            ) {
                try {
                    dispatch(
                        wealthProApi.util.updateQueryData(
                            "searchInvestmentTransactions",
                            {masterAccountId},
                            ({results, pagination}) => {
                                return {
                                    results: results.filter(x => x.investmentTransactionId == investmentTransactionId),
                                    pagination: {
                                        ...pagination
                                    }
                                }
                        }
                        )
                    );
                } catch {
                    //result.undo();
                }
            },
        }),
        searchInvestmentTransactions: builder.query({
            query: (data) => ({
                url: `api/valuations/investmenttransactions/search/`,
                method: 'POST',
                body: data
            }),
            transformResponse: (response, _, { cacheKey }) => {
                return { cacheKey, ...response };
            },
            serializeQueryArgs: ({endpointName, queryArgs}) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                }
                else {
                    currentCache.cacheKey = newItems.cacheKey;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results = [...newItems.results];
                }             
            }
        }),
        updateInvestmentTransaction: builder.mutation({
            query: ({updateData, masterAccountId}) => ({
                url: `api/valuations/investmenttransactions/`,
                body: updateData,
                method: 'PATCH'
            }),
            async onQueryStarted(
                 {updateData, masterAccountId},
                { dispatch, queryFulfilled }
            ) {
                try {
                  const { data: transaction } = await queryFulfilled;
                    dispatch(
                        wealthProApi.util.updateQueryData(
                            "searchInvestmentTransactions",
                            {masterAccountId},
                            ({results, pagination}) => {
                                var index = results.findIndex(x => x.investmentTransactionId == transaction.investmentTransactionId);
                                return {
                                    results: results.map((result, i) => i === index ? {...transaction} : result),
                                    pagination: {
                                        ...pagination
                                    }
                                }
                        }
                        )
                    );
                } catch {
                    //result.undo();
                }
            },
            invalidatesTags: ['investmentTransaction']
        }),
        patchInvestmentTransaction: builder.mutation({
            query: ({ investmentTransactionId, masterAccountId, operations }) => ({
                url: `api/valuations/investmenttransactions/${investmentTransactionId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted(
                 {investmentTransactionId, masterAccountId, operations},
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data: transaction } = await queryFulfilled;
                    dispatch(
                        wealthProApi.util.updateQueryData(
                            "searchInvestmentTransactions",
                            {masterAccountId},
                            ({results, pagination}) => {
                                var index = results.findIndex(x => x.investmentTransactionId == transaction.investmentTransactionId);
                                return {
                                    results: results.map((result, i) => i === index ? {...transaction} : result),
                                    pagination: {
                                        ...pagination
                                    }
                                }
                        }
                        )
                    );
                } catch {
                    
                }
            }
        })
    })
});

export const {
    useGetInvestmentTransactionQuery,
    useCreateTransactionMutation,
    useDuplicateTransactionMutation,
    useReviseTransactionMutation,
    useDeleteTransactionMutation,
    useSearchInvestmentTransactionsQuery,
    useLazySearchInvestmentTransactionsQuery,
    useUpdateInvestmentTransactionMutation,
    usePatchInvestmentTransactionMutation
} = investmentTransactionApiEndpoints;