import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useClientContext } from "../../../hooks/ClientContext";
import { useCreateMessageMutation } from "../../../services/messages";

const ClientMessagingContext = createContext();

export const useClientMessaging = () => useContext(ClientMessagingContext);

export const ClientMessagingViewTypes = {
    Messages: "messages",
    Conversations: "conversations"
};

const ClientMessagingProvider = ({ children }) => {
    const navigate = useNavigate();
    const { id: masterAccountId } = useClientContext();
    const [createMessageTrigger, createMessageResult] = useCreateMessageMutation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [hideSystemMessages, setHideSystemMessages] = useState(true);
    const [feedbackOnly, setFeedbackOnly] = useState(false);
    const [showMyMessages, setShowMyMessages] = useState(false);
    const filters = useMemo(() => ({
        active: searchParams.get("active") === "true",
        clientMessagesOnly: searchParams.get("clientMessagesOnly") === "true",
        feedbackOnly: feedbackOnly,
        hideSystemMessages: hideSystemMessages,
        showMyMessages: showMyMessages,
        threadId: searchParams.get("threadId"),
        unreadMessages: searchParams.get("unreadMessages") === "true",
        viewType: searchParams.get("viewType")
    }), [searchParams, feedbackOnly, hideSystemMessages, showMyMessages]);

    const [selectedViewType, setSelectedViewType] = useState(0);
    const viewTypeOptions = useMemo(() => [
        {
            value: 0,
            label: "All Messages",
            route: `?viewType=${ClientMessagingViewTypes.Messages}`
        },
        {
            value: 1,
            label: "Client Messages",
            route: `?viewType=${ClientMessagingViewTypes.Messages}&clientMessagesOnly=true`
        },
        {
            value: 2,
            label: "All Conversations",
            route: `?viewType=${ClientMessagingViewTypes.Conversations}`
        },
        {
            value: 3,
            label: "Client Conversations",
            route: `?viewType=${ClientMessagingViewTypes.Conversations}&clientMessagesOnly=true`
        },
        {
            value: 4,
            label: "Outstanding Messages",
            route: `?viewType=${ClientMessagingViewTypes.Messages}&active=true`

        },
        {
            value: 5,
            label: "Unread Messages",
            route: `?viewType=${ClientMessagingViewTypes.Messages}&unreadMessages=true&active=true`
        }
    ], []);

    const createMessage = ({ threadId, recipients = [], message = "", isActionRequired = false, messagePriority, threadSubject = null, threadId: replyThreadId = null }) => {
        return new Promise((resolve, reject) => {
            const recipientIds = recipients.map(x => x.userId);
            return createMessageTrigger({
                ...filters,
                threadId: replyThreadId ?? threadId,
                recipientIds,
                masterAccountId,
                message,
                page: 1,
                limit: 25,
                isActionRequired,
                messagePriority,
                threadSubject
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const navigateToThread = (threadId) => {
        if(threadId === null){
            searchParams.delete("threadId");
            setSearchParams(searchParams);
            return;
        }

        searchParams.set("threadId", threadId)
        setSearchParams(searchParams);
    }

    useEffect(() => {
        navigate(viewTypeOptions[selectedViewType].route);
    }, [selectedViewType, viewTypeOptions, navigate]);


    return <ClientMessagingContext.Provider value={{
        createMessageResult,
        filters,
        hideSystemMessages,
        feedbackOnly,
        selectedViewType,
        showMyMessages,
        showThreads: filters.viewType === ClientMessagingViewTypes.Conversations,
        threadId: filters.threadId,
        viewTypeOptions,
        createMessage,
        navigateToThread,
        setHideSystemMessages,
        setFeedbackOnly,
        setSelectedViewType,
        setShowMyMessages,
    }}>
        {children}
    </ClientMessagingContext.Provider>
};

export default ClientMessagingProvider;