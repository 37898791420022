import { ButtonGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components";
import useAdminNewMoney from "../hooks/useAdminNewMoney";
import useNewMoneyFormTitle from "../hooks/useNewMoneyFormTitle";
import NewMoneyForm from "./NewMoneyForm";

export const NewMoneyCreateModal = ({ show, handleClose }) => {
    const { createNewMoney, createApprovedNewMoney, isLoading } = useAdminNewMoney();

    const form = useForm({
        defaultValues: {
            isOutflow: false,
            adviserId: null,
            masterAccountId: null,
            masterAccountName: null,
            description: null,
            investmentAmount: 0,
            serviceFeePercent: 0,
            serviceFeeAmount: 0,
            newMoneyDate: null,
        }
    });
    const { reset, watch, handleSubmit } = form;

    const clearAndClose = () => {
        reset();
        handleClose();
    }

    const title = useNewMoneyFormTitle(watch);

    const onSubmit = (data) => {
        clearAndClose();
        createNewMoney(data);
    }

    const onSubmitAndApprove = (data) => {
        clearAndClose();
        createApprovedNewMoney(data);
    }

    return <Modal size="xl" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
            <div>
                <h4>Register New Money</h4>
                <h5 className="mb-0">{title}</h5>
            </div>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmitAndApprove)}>
            <Modal.Body>
                <NewMoneyForm form={form} showMasterAccount calculateServiceFee requireFields />
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button variant="danger" onClick={clearAndClose} disabled={isLoading}>Cancel</Button>
                    <Button variant="warning" onClick={handleSubmit(onSubmit)} disabled={isLoading}>Create</Button>
                    <Button variant="success" type='submit' disabled={isLoading}>Create and Approve</Button>
                </ButtonGroup>
            </Modal.Footer>
        </form>
    </Modal>
}