import { formatCurrency } from "./useAdminNewMoney";

const formatDate = (value) => new Date(value).toLocaleDateString("en-GB", { dateStyle: "medium" });

const makeTitle = (description, investmentAmount, feeAmount, masterAccountName, newMoneyDate) => {
    // Use the description if it's already set, everything else is a fallback
    if (description) return description;

    // Want it to be "£{invAmount} (£{feeAmount}) for {masterAccountName} on {date}"
    // If no date, then "£{invAmount} (£{feeAmount}) for {masterAccountName}"
    // If no master account, then "£{invAmount} (£{feeAmount}) on {date}"
    // If no inv amount, then "{masterAccountName} (date)"
    const start = investmentAmount > 0
        ? `${formatCurrency(investmentAmount)} (${formatCurrency(feeAmount)}) `
        : null;

    const middle = masterAccountName
        ? `${masterAccountName}`
        : null;

    const startAndMiddle = [start, middle].filter(Boolean).join(" for ");

    const end = newMoneyDate
        ? investmentAmount > 0
            ? `on ${formatDate(newMoneyDate)}`
            : `(${formatDate(newMoneyDate)})`
        : null;

    return [startAndMiddle, end].filter(Boolean).join(" ");
}

const useNewMoneyFormTitle = (watch) => {
    const description = watch('description');
    const investmentAmount = watch('investmentAmount');
    const serviceFeeAmount = watch('serviceFeeAmount');
    const masterAccountName = watch('masterAccountName');
    const newMoneyDate = watch('newMoneyDate');

    const title = makeTitle(description, investmentAmount, serviceFeeAmount, masterAccountName, newMoneyDate);

    return title;
}

export default useNewMoneyFormTitle;