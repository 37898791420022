import { useFetchNewMoneyOverwriteLogsQuery } from "../../../services/headofficeadmin";

const useNewMoneyOverwriteLogs = (id) => {
    const { data, error, isFetching, refetch } = useFetchNewMoneyOverwriteLogsQuery({ id }, { refetchOnMountOrArgChange: false });

    return {
        logs: data,
        isFetching,
        error,
        refetch
    }
}

export default useNewMoneyOverwriteLogs;