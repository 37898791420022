import moment from "moment";
import React from "react";
import Moment from "react-moment";
import useOpenMasterAccount from "../../../hooks/useOpenMasterAccount";
import ReviewManagementDetailTableRowColumn from "./ReviewManagementDetailTableRowColumn";
import ReviewManagementDetailTableOnHoldIconColumn from "./ReviewManagementDetailTableOnHoldIconColumn";
import ReviewManagementDetailTableStatusIconColumn from "./ReviewManagementDetailTableStatusIconColumn";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";
import ReviewManagementDetailTableRowActionColumn from "./ReviewManagementDetailTableRowActionColumn";
import { useAuth } from "react-oidc-context";
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";

const LargePopover = styled(Popover)`
max-width: 500px;
`;

const ReviewManagementDetailTableRow = ({ data, index, style }) => {
    const { height } = style;
    const openMasterAccount = useOpenMasterAccount();

    const {
        user: { profile },
    } = useAuth();

    const allowAssignAuthorRoles = [
        "sys_admin_access",
        "report_assign_author",
        "report_assign_self",
    ];

    const isAllowedToAssignAuthor = _.some(profile.roles, (item) =>
        allowAssignAuthorRoles.includes(item)
    );

    // do not return a data row when the data has not yet been collected from the backend
    if (index >= data.length) {
        return <TableLoadingRow />;
    }

    const {
        reviewId,
        reviewAdviserId,
        reviewAdviser,
        appointmentScore,
        appointmentScheduledDate,
        appointmentDate,
        appointmentScheduledTargetDate,
        followUpScore,
        followUpSentDate,
        followUpSentTargetDate,
        followUpStatusComment,
        masterAccountId,
        masterAccountReference,
        masterAccountName,
        meetingNotesScore,
        meetingNotesUpdatedDate,
        meetingNotesUpdatedTargetDate,
        preReviewDocumentScore,
        preReviewDocumentSentDate,
        preReviewDocumentSentTarget,
        reviewDate,
        reviewStatus,
        reviewStatusComment,
        verificationScore,
        verificationTargetDate,
        verificationStatusComment,
        verifiedDate,
        assignedToDrawdownUser,
        assignedToValuationUser,
        assignedToAppointmentUser,   
        appointmentStyleText,
        appointmentStatusText,
        appointmentStatusComment,
        preReviewDocumentAuthor,     
        preReviewDocumentStatusText,  
        preReviewDocumentStatusComment, 
        followUpAuthor,
        followUpReportStatusText,
        isDrawdownReview,
        delayed

    } = data[index];

    const handleOpenMasterAccount = (e) => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }
        openMasterAccount(masterAccountId, { suffix: "reviews" });
    };

    return (
        <tr>
            {/* Client Name Display */}
            <ReviewManagementDetailTableRowColumn width={25}>
                <a href={`/client/${masterAccountId}/reviews`} onClick={handleOpenMasterAccount}>
                    <span>
                        {masterAccountReference} - {masterAccountName}
                    </span>
                </a>
            </ReviewManagementDetailTableRowColumn>
            {/* Adviser */}
            <ReviewManagementDetailTableRowColumn width={12.5}>
                <span>{reviewAdviser}</span>
            </ReviewManagementDetailTableRowColumn>
            {/* Review Date */}
            <ReviewManagementDetailTableRowColumn width={12.5}>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <LargePopover>
                            <Popover.Header>Review Assigned Users</Popover.Header>
                            <Popover.Body>
                                <div style={{ textAlign: "left" }}>
                                    {/* Overall Review Comment found at the top of the review */}
                                    {reviewStatusComment != null && reviewStatusComment != "" && <><b>Review Comment: </b><span>{reviewStatusComment}</span><br/></>}
                                    {/* Valuation Comment and information */}
                                    <b>Valuation:</b> {assignedToValuationUser}<br/>
                                    {verificationStatusComment != null && verificationStatusComment != "" && <><b>Valuation Comment:</b> <span>{verificationStatusComment}</span><br/></>}
                                    {/* Drawdown information */}
                                    {isDrawdownReview && <><b>Drawdown:</b> {assignedToDrawdownUser}<br/></>}
                                    {/* PRD Comment and information */}
                                    <b>PRD:</b> {preReviewDocumentAuthor} {preReviewDocumentStatusText !== "Not Set" ? (<span>({preReviewDocumentStatusText})</span>) : null} <br/>
                                    {preReviewDocumentStatusComment != null && preReviewDocumentStatusComment != "" && <><b>PRD Comment:</b> <span>{preReviewDocumentStatusComment}</span><br/></>}
                                    {/* Appointment Comment and information */}
                                    {appointmentDate == null && <><b>Appointment:</b> {assignedToAppointmentUser}<br/></>}
                                    {appointmentDate != null && <><b>Appointment:</b> <Moment format="DD/MM/YYYY hh:mm" date={appointmentDate} /> - {appointmentStyleText} - {appointmentStatusText}<br/></>}
                                    {appointmentStatusComment != null && appointmentStatusComment != "" && <><b>Appointment Comment:</b> <span>{appointmentStatusComment}</span><br/></>}
                                    {/* Follow Up Comment and information */}
                                    <b>Follow Up:</b> {followUpAuthor} {followUpReportStatusText !== "Not Set" ? (<span>({followUpReportStatusText})</span>) : null}
                                    {followUpStatusComment != null && followUpStatusComment != "" && <><br/><b>Follow Up Comment:</b> <span>{followUpStatusComment}</span></>}
                                </div>
                            </Popover.Body>
                        </LargePopover>
                    }
                >
                    {reviewDate ? (
                        <Moment format="DD/MM/YYYY">{reviewDate}</Moment>
                    ) : (
                        <span>-</span>
                    )}
                </OverlayTrigger>
            </ReviewManagementDetailTableRowColumn>
            {/* Valuation */}
            <ReviewManagementDetailTableStatusIconColumn
                reviewDate={reviewDate}
                date={verifiedDate}
                score={verificationScore}
                targetDate={verificationTargetDate}
                previousComplete={true}
            />
            {/* PRD Issued */}
            <ReviewManagementDetailTableStatusIconColumn
                reviewDate={reviewDate}
                date={preReviewDocumentSentDate}
                score={preReviewDocumentScore}
                targetDate={preReviewDocumentSentTarget}
                previousComplete={verifiedDate != null}
            />
            {/* Scheduled */}
            <ReviewManagementDetailTableStatusIconColumn
                reviewDate={reviewDate}
                date={appointmentScheduledDate}
                score={appointmentScore}
                targetDate={appointmentScheduledTargetDate}
                previousComplete={preReviewDocumentSentDate != null}
            />
            {/* Notes */}
            <ReviewManagementDetailTableStatusIconColumn
                reviewDate={reviewDate}
                date={meetingNotesUpdatedDate}
                score={meetingNotesScore}
                targetDate={meetingNotesUpdatedTargetDate}
                previousComplete={
                    appointmentDate != null &&
                    moment(appointmentDate).isBefore()
                }
            />
            {/* Follow-up */}
            <ReviewManagementDetailTableStatusIconColumn
                reviewDate={reviewDate}
                date={followUpSentDate}
                score={followUpScore}
                targetDate={followUpSentTargetDate}
                previousComplete={meetingNotesUpdatedDate != null}
            />
            {/* On Hold */}
            <ReviewManagementDetailTableOnHoldIconColumn
                status={reviewStatus}
                delayed={delayed}
                statusDate={null}
            />
            {/* Action */}
            <ReviewManagementDetailTableRowActionColumn review={data[index]} />
        </tr>
    );
};

export default ReviewManagementDetailTableRow;
