import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { CurrencyInput, DateInput, FormInput, FormSelect, FormTextArea, PercentageInput } from "../../../components/forms";
import { GenericAdviserSelect, MasterAccountSelect } from "../../../components/forms/selects";

const NewMoneyForm = ({
    form: {
        watch,
        control,
        setValue,
        formState: { errors }
    },
    disabled = false,
    showOverwriteComment = false,
    showMasterAccount = false,
    calculateServiceFee = false,
    requireFields = false
}) => {

    const watchInvestmentAmount = watch("investmentAmount");
    const watchServicePercentAmount = watch("serviceFeePercent");

    // Calculate fee amount based on investment and rate
    useEffect(() => {
        if (!calculateServiceFee) return;

        const feeAmount = Math.floor(watchInvestmentAmount * watchServicePercentAmount / 100)
        setValue("serviceFeeAmount", feeAmount);
    }, [calculateServiceFee, setValue, watchInvestmentAmount, watchServicePercentAmount]);

    return <div className="row gy-2">
        {showMasterAccount && <div className="col-12">
            <Controller
                name="masterAccountId"
                control={control}
                render={({ field: { ref, value, onChange, ...rest } }) => <MasterAccountSelect
                    label="Master Account"
                    value={value}
                    setValue={({ value, data: { accountName } }) => {
                        setValue("masterAccountName", accountName);
                        setValue("masterAccountId", value);
                    }}
                    errorMessage={errors?.masterAccountId?.message}
                    {...rest}
                />}
            />
        </div>}
        <div className="col-3">
            <Controller
                control={control}
                name="isOutflow"
                render={({ field: { ref, onChange, value, ...rest } }) =>
                    <FormSelect
                        label="Flow"
                        options={[{
                            label: "New Money",
                            value: false
                        }, {
                            label: "Outflow",
                            value: true
                        }]}
                        onChange={selection => onChange(selection.value)}
                        defaultValue={value}
                        disableAnimations
                        disabled={disabled}
                        {...rest}
                    />
                } />
        </div>
        <div className="col-3">
            <Controller
                control={control}
                name="adviserId"
                rules={{ required: requireFields && "Adviser is required." }}
                render={({ field: { ref, onChange, value, ...rest } }) =>
                    <GenericAdviserSelect
                        label="Adviser"
                        defaultValue={value}
                        onChange={selection => {
                            onChange(selection.value);
                            setValue('adviserName', selection.label, {
                                shouldDirty: true
                            });
                        }}
                        disableAnimations
                        disabled={disabled}
                        {...rest}
                    />
                } />
        </div>
        <div className="col-6">
            <Controller
                control={control}
                name="description"
                rules={{ required: requireFields && "Description is required." }}
                render={({ field: { ref, onChange, value, ...rest } }) =>
                    <FormInput
                        label="Description"
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        disableAnimations
                        disabled={disabled}
                        {...rest}
                    />
                } />
        </div>
        <div className="col-3">
            <Controller
                control={control}
                name="investmentAmount"
                rules={{ required: requireFields && "Investment amount is required." }}
                render={({ field: { ref, onChange, value, ...rest } }) =>
                    <CurrencyInput
                        label="Investment"
                        value={value}
                        onChange={(_, values) => onChange(values.floatValue ?? "")} disableAnimations
                        decimalScale={0}
                        fixedDecimalScale
                        disabled={disabled}
                        {...rest}
                    />
                } />
        </div>
        <div className="col-2">
            <Controller
                control={control}
                name="serviceFeePercent"
                rules={{ required: requireFields && "Service Fee rate is required." }}
                render={({ field: { ref, onChange, value, ...rest } }) =>
                    <PercentageInput
                        label="Rate (%)"
                        value={value}
                        onChange={(_, values) => onChange(values.floatValue ?? 0)}
                        disableAnimations
                        decimalScale={2}
                        fixedDecimalScale
                        disabled={disabled}
                        {...rest}
                    />
                } />
        </div>
        <div className="col-3">
            <Controller
                control={control}
                name="serviceFeeAmount"
                rules={{ required: requireFields && "Service Fee amount is required." }}
                render={({ field: { ref, onChange, value, ...rest } }) =>
                    <CurrencyInput
                        label="Service Fee (£)"
                        value={value}
                        onChange={(_, values) => onChange(values.floatValue ?? 0)}
                        disabled={disabled || calculateServiceFee}
                        disableAnimations
                        decimalScale={0}
                        fixedDecimalScale
                        {...rest}
                    />
                } />
        </div>
        <div className="col-4">
            <Controller
                control={control}
                name="newMoneyDate"
                rules={{ required: requireFields && "Date is required." }}
                render={({ field: { ref, onChange, onBlur, value, ...rest } }) =>
                    <DateInput
                        label="Date"
                        value={value}
                        onBlur={newValue => onChange(newValue)}
                        disableAnimations
                        disabled={disabled}
                        {...rest}
                    />
                } />
        </div>
        {showOverwriteComment && <div className="col-12">
            <div className="alert alert-warning my-2">
                <strong>Warning:</strong> This new money has already been processed. Please enter a reason for overwriting the existing data.
            </div>
            <Controller
                control={control}
                name="overwriteComment"
                rules={{ required: showOverwriteComment && "A justification must be provided to update processed New Money" }}
                render={({ field: { ref, onChange, name, value, ...rest } }) =>
                    <FormTextArea
                        label="Overwrite Comment"
                        value={value}
                        onChange={e => setValue(name, e.target.value, {
                            shouldDirty: false,
                            shouldValidate: true
                        })}
                        disabled={disabled}
                        disableAnimations
                        errorMessage={errors?.overwriteComment?.message}
                        errorAllowRetry={false}
                        {...rest}
                    />
                } />
        </div>}
    </div>;
}

export default NewMoneyForm;