import { createContext, useContext, useEffect, useState } from "react";
import { useCreateAssetMutation, useDeleteAssetMutation, useLazyFetchAssetsForGridQuery, usePatchAssetMutation } from "../../../services/assets/assetApiEndpoints";
import { patchReplace } from "../../../helpers/patchDoc";
import { useFilterContext } from "../../../hooks/FilterContext";


const AssetsManagementContext = createContext();

export const useAssetsManagement = () => useContext(AssetsManagementContext);

const AssetsManagementProvider = ({ children }) => {

    const limit = 50;
    
    const { filter, isReady } = useFilterContext();
    
    
    const [isClearing, setIsClearing] = useState(false);

    const [sort, setSort] = useState("AssetName");
    const [assetIdToDelete, setAssetIdToDelete] = useState(null);
    const [assetIdToEdit, setAssetIdToEdit] = useState(null);

    const [searchAssets, searchAssetsResult] = useLazyFetchAssetsForGridQuery();
    const { data, isError, error, isUninitialized, isLoading, isFetching } = searchAssetsResult;
    const { next, results, totalCount } = data || { next: null, results: [], totalCount: 0 };

    const [createTrigger, createResult] = useCreateAssetMutation();
    const [deleteTrigger, deleteResult] = useDeleteAssetMutation();
    const [patchTrigger, patchResult] = usePatchAssetMutation();
    
    const isNextPageLoading = isFetching === true || isLoading === true;

    const clear = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading || !isReady) {
            return;
        }

        setIsClearing(true);
        searchAssets({
            next: null,
            sort,
            limit,
            request: {
                ...filter,
                providerId: filter.provider?.value,
            }
        }).unwrap().then(_ => {
        }).finally(_ => setIsClearing(false));
    };

    const createAsset = () => {
        return new Promise((resolve, reject) => {
            return createTrigger({}).unwrap().then(resolve, reject);
        });
    };

    const deleteSelectedAsset = () => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({ assetId: assetIdToDelete }).unwrap().then(resolve, reject);
        });
    };

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = (e) => {

        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading || totalCount < limit) {
            return;
        }

        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        return searchAssets({
            next,
            sort,
            limit,
            request: {
                ...filter,
                providerId: filter.provider?.value,
            }
        });
    };

    const patchSelectedAsset = (property, newValue, assignableObject) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return patchTrigger({ assetId: assetIdToEdit, operations, assignableObject }).unwrap().then(resolve, reject);
        });
    };

    useEffect(clear, [filter]);

    const columns = [
        {
            label: "Asset Name",
        },
        {
            label: "Provider",
        },
        {
            label: "Fund Code",
        },
        {
            label: "Citicode",
        },
        {
            label: "SEDOL",
        },
        {
            label: "Sector Code",
        },
        {
            label: "Inc/Acc",
        },
        {
            label: "Finex Subcategory",
        },
        {
            label: "Currency",
        },
        {
            label: "Priced",
            className: "text-center"
        },
        {
            label: "Mid Price",
        },
        {
            label: "Bid Price",
        },
        {
            label: "Offer Price",
        },
        {
            label: "Price Date",
        }
    ];

    return <AssetsManagementContext.Provider value={{
        filter,
        assetToDelete: assetIdToDelete ? results.find(x => x.assetId === assetIdToDelete) : null,
        assetToEdit: assetIdToEdit ? results.find(x => x.assetId === assetIdToEdit) : null,
        columns,
        createResult,
        data,
        error,
        isError,
        isNextPageLoading,
        limit,
        results: isClearing ? [] : results,
        sort,
        totalCount,
        clear,
        createAsset,
        deleteSelectedAsset,
        isItemLoaded,
        loadMoreItems,
        patchSelectedAsset,
        setAssetIdToDelete,
        setAssetIdToEdit,
        setSort,
    }}>
        {children}
    </AssetsManagementContext.Provider>

};

export default AssetsManagementProvider;