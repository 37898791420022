import { useCallback, useEffect, useMemo, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchValuationProvidersQuery } from "../../../services/valuations";

const useReferenceSelection = ({
    providerRef,
    custodyRef,
    patch,
    realTimeDesignationId,
    realTimeProductId = null,
    realTimeProductDefaultCustodianId = null,
    hasLoaded,
    isInvestmentCash = false // Inv cash has no consideration of Custody ID
}) => {
    const { id: masterAccountId } = useClientContext();
    const [realTimeProviderRef, setRealTimeProviderRef] = useState(null);

    useEffect(() => {
        setRealTimeProviderRef(providerRef);
    }, [providerRef]);

    const [fetchProvidersTrigger, { isFetching: isFetchingProviders, isUninitialized: isUninitializedProviders }] = useLazyFetchValuationProvidersQuery();
    const [providersMapInState, setProvidersMapInState] = useState({});

    const [lastProviderRef, setLastProviderRef] = useState(null);
    const [lastCustodyRef, setLastCustodyRef] = useState(null);

    useEffect(() => {
        if (!hasLoaded)
            return;

        if (lastProviderRef == null)
            setLastProviderRef(providerRef);

        if (lastCustodyRef == null)
            setLastCustodyRef(custodyRef)
    }, [custodyRef, hasLoaded, lastCustodyRef, lastProviderRef, providerRef]);

    useEffect(() => {
        setRealTimeProviderRef(providerRef)
    }, [providerRef])

    const providerOptions = useMemo(() => [
        ...new Set(Object.keys(providersMapInState ?? {}))
    ].map(p => ({ value: p, label: p })),
        [providersMapInState]);

    const custodyOptions = useMemo(() => [
        ...new Set(providersMapInState?.[realTimeProviderRef] ?? Object.values(providersMapInState ?? {}).flat())
    ].map(c => ({ value: c.custodianAccountReference, label: c.custodianAccountReference, custodyId: c.custodianId }))
        // First ensure that there is only one occurrence per (value, custodyId) pair
        .filter((elt, i, arr) => arr.findIndex(item => item.value === elt.value && item.custodyId === elt.custodyId) === i)
        // Filter out duplicates with following alg:
        // 1. If there are no options with the same value and the default custodian, keep the option if it is the first occurrence of that value
        // 2. If there are options with the same value and the default custodian, keep the option if it is the default custodian
        .filter((elt, i, arr) => !arr.some(item => item.value === elt.value && item.custodyId === realTimeProductDefaultCustodianId)
            ? arr.findIndex(item => item.value === elt.value) === i
            : elt.custodyId === realTimeProductDefaultCustodianId),
        [providersMapInState, realTimeProductDefaultCustodianId, realTimeProviderRef]);

    const addCustomProvider = (newValue) => {
        setProvidersMapInState(oldMap => ({
            // New option should have all custody options from the old map, with duplicates removed
            [newValue.toUpperCase()]: [{ custodianAccountReference: newValue.toUpperCase(), custodianId: realTimeProductDefaultCustodianId }, ...Object.values(oldMap).flat().filter((elt, i, arr) => i === arr.findIndex((duplicate) => duplicate === elt))],
            ...oldMap,
        }));

        // Update the providerRef to this new value
        patch([patchReplace("providerRef", newValue.toUpperCase())]);
    };

    const addCustomCustodian = (newValue) => {
        const newCustodian = { custodianAccountReference: newValue.toUpperCase(), custodianId: realTimeProductDefaultCustodianId };
        setProvidersMapInState(oldMap => {
            // New custodian option should be added to all providers
            const newMap = { ...oldMap };
            Object.keys(newMap).forEach(provider => {
                newMap[provider] = [newCustodian, ...newMap[provider]];
            });

            // Update the custodyRef to this new value
            let ops = [patchReplace("custodyRef", newCustodian.custodianAccountReference)];
            if (!isInvestmentCash)
                ops.push(patchReplace("custodyId", newCustodian.custodianId));

            patch(ops);

            return newMap;
        });
    };

    useEffect(() => {
        const fetchProvidersTimeout = setTimeout(() => {
            if (realTimeProductId != null || realTimeDesignationId != null) {
                fetchProvidersTrigger({ masterAccountId, productId: realTimeProductId, designationId: realTimeDesignationId }).unwrap()
                    .then((providersMap) => {
                        if (!providersMap)
                            return;

                        setProvidersMapInState(_ => {
                            if (lastProviderRef == null && lastCustodyRef == null)
                                return providersMap;

                            var newMap = { ...providersMap };

                            if (lastProviderRef != null) {
                                if (newMap[lastProviderRef] == null)
                                    newMap[lastProviderRef] = [];
                                else
                                    newMap[lastProviderRef] = [...new Set([...newMap[lastProviderRef]])];
                            }

                            if (lastCustodyRef != null) {
                                // Check if the last custody ref is in the new map so we can use the correct custodianId
                                const custodian = newMap[lastProviderRef]?.find(c => c.custodianAccountReference === lastCustodyRef)
                                    ?? { custodianAccountReference: lastCustodyRef, custodianId: realTimeProductDefaultCustodianId };

                                Object.keys(newMap).forEach(provider => {
                                    newMap[provider] = [...newMap[provider], custodian];
                                });
                            }

                            return newMap;
                        })
                    })
                    .catch((err) => {
                        console.error(err);
                        if (lastProviderRef == null && lastCustodyRef == null)
                            return;

                        setProvidersMapInState({
                            [lastProviderRef]: [{ custodianAccountReference: lastCustodyRef, custodianId: realTimeProductDefaultCustodianId }]
                        })
                    });
            } else {
                setProvidersMapInState(null);
            }
        }, 150);

        return () => clearTimeout(fetchProvidersTimeout);
    }, [fetchProvidersTrigger, lastCustodyRef, lastProviderRef, masterAccountId, realTimeDesignationId, realTimeProductDefaultCustodianId, realTimeProductId]);

    useEffect(() => {
        if (realTimeDesignationId == null)
            return;

        let ops = []

        if (providerOptions.length === 1 && providerRef !== providerOptions[0].value.toUpperCase())
            ops.push(patchReplace("providerRef", providerOptions[0].value.toUpperCase()));

        if (custodyOptions.length === 1 && custodyRef !== custodyOptions[0].value.toUpperCase()) {
            ops.push(patchReplace("custodyRef", custodyOptions[0].value.toUpperCase()));

            if (!isInvestmentCash)
                ops.push(patchReplace("custodyId", custodyOptions[0].custodyId));
        }

        if (ops.length === 0)
            return;

        patch(ops);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [custodyOptions, providerOptions])

    return {
        isFetchingProviders,
        isUninitializedProviders,
        providerOptions,
        custodyOptions,
        realTimeProviderRef,
        setRealTimeProviderRef,
        addCustomProvider,
        addCustomCustodian,
    }
}

export default useReferenceSelection;