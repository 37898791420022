import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import { Button } from "../../../components";
import { FormTextArea } from "../../../components/forms";
import { StandardTableSortablePropertyHeader, StickyThemedTableHeader } from "../../../components/tables/StandardTable";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useLazySearchPortfolioTestRunsQuery } from "../../../services/valuations";
import { useUpdateMasterAccountFlaggedNotesMutation, useUpdateMasterAccountFlaggedStatusMutation } from "../../../services/valuations/portfolioTestingApiEndpoints";
import { flaggedPortfolioStatuses } from "../components/PortfolioTestResultFilters";

const MasterAccountFlaggedStatusToggle = React.forwardRef(({ className, icon, ...props }, ref) => (
    <FontAwesomeIcon
        className={classNames(className, "has-pointer")}
        ref={ref}
        icon="flag"
        {...props}
    />
));

const MasterAccountFlaggedStatusActionMenuItem = ({ label, uncheckedLabel = null, value, currentValue }) => {
    const showCheck = useMemo(() => value === currentValue, [value, currentValue]);

    return <Dropdown.Item
        className="d-flex justify-content-between align-items-center"
        eventKey={value}
    >
        {(!showCheck ? uncheckedLabel : label) ?? label}
        {showCheck && <FontAwesomeIcon
            icon="check"
            className="text-success ms-2"
        />}
    </Dropdown.Item>
}

const MasterAccountFlaggedNotesModal = ({ show, masterAccountName, notes, onClose, onSave }) => {
    const [notesText, setNotesText] = useState(notes ?? "");

    const handleClose = () => {
        setNotesText(notes);
        onClose();
    }

    const handleSave = () => {
        onSave(notesText);
        onClose();
    }

    return <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Add Notes to {masterAccountName?.trim() ?? "This Master Account"}'s flag information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormTextArea
                value={notesText}
                onChange={e => setNotesText(e.target.value)}
                disableAnimations
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
                Cancel
            </Button>
            <Button variant="success" onClick={handleSave}>
                Save
            </Button>
        </Modal.Footer>
    </Modal>
}

const MasterAccountFlaggedStatusCell = ({ row, updateFlagStatus, updateFlagNotes }) => {
    const { masterAccountId, masterAccountName, masterAccountFlagStatus, masterAccountFlagNotes } = row;

    const [showNotesModal, setShowNotesModal] = useState(false);

    const handleSelect = (eventKey) => {
        const value = parseInt(eventKey);

        if (!isNaN(value))
            updateFlagStatus(masterAccountId, value, masterAccountName);
        else if (eventKey === 'notes')
            setShowNotesModal(true);
    }

    const handleSaveNotes = (newNotes) => {
        updateFlagNotes(masterAccountId, newNotes);
        setShowNotesModal(false);
    }

    const colour = flaggedPortfolioStatuses.find(s => s.value === masterAccountFlagStatus)?.colour;

    return <>
        <MasterAccountFlaggedNotesModal
            show={showNotesModal}
            masterAccountName={masterAccountName}
            notes={masterAccountFlagNotes}
            onClose={() => setShowNotesModal(false)}
            onSave={handleSaveNotes}
        />
        <Dropdown className="caret-off w-100" onSelect={handleSelect}>
            <Dropdown.Toggle
                as={MasterAccountFlaggedStatusToggle}
                color={colour}
            />
            <Dropdown.Menu>
                {flaggedPortfolioStatuses.map(({ label, uncheckedLabel, value }) =>
                    <MasterAccountFlaggedStatusActionMenuItem
                        key={value}
                        label={label}
                        uncheckedLabel={uncheckedLabel}
                        value={value}
                        currentValue={masterAccountFlagStatus}
                    />
                )}
                {masterAccountFlagStatus !== 0 && <Dropdown.Item eventKey="notes">View/Edit Notes</Dropdown.Item>}
            </Dropdown.Menu>
        </Dropdown>
    </>;
}

const MasterAccountInformationCell = ({ row }) => {
    const { masterAccountId, masterAccountName } = row;

    return <a href={`/client/${masterAccountId}`}>
        {masterAccountName}
    </a>;
}

const TooltipTableHeader = ({ index, sortProperty, property, currentSort, onClickSort, children, sort }) => {
    return sort
        ? <StandardTableSortablePropertyHeader
            key={index}
            property={sortProperty ?? property}
            sort={currentSort ?? ""}
            sortTable={onClickSort}
        >
            {children}
        </StandardTableSortablePropertyHeader>
        : <StickyThemedTableHeader key={index}>
            {children}
        </StickyThemedTableHeader>;
};

const TooltipHeaderRenderer = ({
    label,
    property,
    sortProperty,
    currentSort,
    tooltipText,
    sort = true,
    onClickSort
}) => {
    const renderTooltip = (props) => (
        <Tooltip {...props}>
            {tooltipText}
        </Tooltip>
    );

    return <TooltipTableHeader
        property={property}
        sortProperty={sortProperty}
        currentSort={currentSort}
        onClickSort={onClickSort}
        sort={sort}
    >
        <OverlayTrigger overlay={renderTooltip}>
            <span>{label}</span>
        </OverlayTrigger>
    </TooltipTableHeader>
}

const defaultColumns = [
    {
        label: "Master Account",
        CellComponent: MasterAccountInformationCell,
        width: 25
    },
    {
        label: "£",
        property: "totalPortfolioValue",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Total Portfolio Value",
        sort: true,
        cellFormatter: (value) => value.toLocaleString("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }),
        className: "text-end",
        width: 10
    },
    {
        label: "Target",
        property: "targetProfile",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Client's Target Profile",
        sort: true,
        sortProperty: "targetProfileSortOrder",
        width: 20
    },
    {
        label: "Actual",
        property: "actualProfile",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Client Profile matching actual portfolio volatility score",
        sort: true,
        sortProperty: "actualProfileSortOrder",
        width: 20
    },
    {
        label: "Diff.",
        property: "volatilityDifference",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Difference between target and actual volatility scores",
        sort: true,
        sortProperty: "absoluteVolatilityDifference",
        cellFormatter: (value) => value.toFixed(0) + "%",
        className: "text-end",
        width: 5
    },
    {
        label: "Fund Max",
        property: "fundMaxTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Number of funds exceeding the maximum % allocation of the target profile",
        sort: true
    },
    {
        label: "Wrapper Max",
        property: "wrapperMaxTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Number of wrapper rules that failed the maximum % allocation of the target profile",
        sort: true
    },
    {
        label: "Provider Max",
        property: "providerMaxTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Number of providers exceeding the maximum % allocation of the target profile",
        sort: true
    },
    {
        label: "Vol",
        property: "volatilityTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Total number of Volatility classification tests that failed",
        sort: true
    },
    {
        label: "Fund Max (Vol)",
        property: "volatilityFundMaxTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Number of funds exceeding the maximum % allocation of a given volatility classification",
        sort: true
    },
    {
        label: "Combined (Vol)",
        property: "volatilityClassGroupTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Number of funds exceeding the maximum % allocation of a given volatility group rule",
        sort: true
    },
    {
        label: "Specialist Max (Vol)",
        property: "volatilityClassGroupFundMaxTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Number of funds exceeding the maximum fund allocation of a given volatility group rule",
        sort: true
    },
    {
        label: "Div",
        property: "diversificationTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Total number of Diversification classification tests that failed",
        sort: true
    },
    {
        label: "Fund Max (Div)",
        property: "diversificationFundMaxTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Number of funds exceeding the maximum % allocation of a given diversification classification",
        sort: true
    },
    {
        label: "Combined (Div)",
        property: "diversificationClassGroupTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Number of funds exceeding the maximum % allocation of a given diversification group rule",
        sort: true
    },
    {
        label: "Specialist Max (Div)",
        property: "diversificationClassGroupFundMaxTestsFailed",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Number of funds exceeding the maximum fund allocation of a given diversification group rule",
        sort: true
    },
    {
        label: "Score",
        property: "volatilityScore",
        headerRenderer: TooltipHeaderRenderer,
        tooltipText: "Client's actual volatility score",
        sort: true,
        cellFormatter: (value) => value.toFixed(3),
        width: 5
    }
];

const usePortfolioTestResults = () => {
    const [isClearing, setIsClearing] = useState(false);

    const {
        filter
    } = useFilterContext();
    const [sort, setSort] = useState(null);
    const [limit] = useState(50);
    const splitSort = useMemo(() => (sort ?? "").split("+"), [sort]);
    const [cacheKey, setCacheKey] = useState(new Date().valueOf());

    const [search, { data, ...searchResult }] = useLazySearchPortfolioTestRunsQuery();

    const { isFetching, error } = searchResult;

    const { pagination, results } = data || { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = useMemo(() => totalCount > 0 && page < totalPages, [page, totalCount, totalPages]);

    const clear = () => {
        if (isFetching)
            return;

        const newCacheKey = new Date().valueOf();
        setIsClearing(true);
        search({
            cacheKey: newCacheKey,
            filters: filter,
            page: 1,
            limit,
            sort
        }).unwrap()
            .then(() => setCacheKey(newCacheKey))
            .finally(() => setIsClearing(false));
    };

    useEffect(() => {
        clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, sort]);

    const loadMore = () => {
        // Safety check to return early if we are already loading a page
        if (isFetching)
            return;

        search({ cacheKey, filters: filter, page: page + 1, limit, sort });
    };

    const isRowLoaded = useCallback((index) => !hasMore || index < results.length, [hasMore, results]);

    const onClickSort = (property) => {
        setSort(oldSort => {
            const [oldProperty, oldDirection] = (oldSort ?? "").split("+");
            let newDirection = "";

            if (property === oldProperty) {
                newDirection = oldDirection === "DESC" ? null : "+DESC";
            }

            return property + (newDirection ?? "");
        });
    };

    // Add flag column to column defs with update capability
    const [updateFlagStatusTrigger] = useUpdateMasterAccountFlaggedStatusMutation();
    const [updateFlagNotesTrigger] = useUpdateMasterAccountFlaggedNotesMutation();

    const updateFlagStatus = (masterAccountId, flaggedStatus, masterAccountName = null) => updateFlagStatusTrigger({ masterAccountId, flaggedStatus }).unwrap()
        .then(
            () => toast.success("Flag status updated" + (masterAccountName ? ` for ${masterAccountName}` : "")),
            () => toast.error("Failed to update flag status" + (masterAccountName ? ` for ${masterAccountName}` : ""))
        );

    const updateFlagNotes = (masterAccountId, notes) => updateFlagNotesTrigger({ masterAccountId, notes }).unwrap()
        .then(
            () => toast.success("Flag notes updated"),
            () => toast.error("Failed to update flag notes")
        );

    const columns = [{
        className: 'action-col',
        CellComponent: MasterAccountFlaggedStatusCell,
        updateFlagStatus,
        updateFlagNotes,
        sort: true,
        sortProperty: 'masterAccountFlagStatus',
        overflow: true
    }, ...defaultColumns];

    return {
        clear,
        isClearing,
        isRowLoaded,
        loadMore,
        currentSort: sort,
        splitSort,
        onClickSort,
        totalCount,
        results: isClearing || error ? [] : results,
        columns,
        ...searchResult
    };
}

export default usePortfolioTestResults;