import _ from "lodash";
import React, { useId, useState } from "react";
import CreateClientModal from "../createclientmodal";
import CreatePersonalMessageModal from "../createpersonalmessagemodal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";

const CreateDropdown = () => {
    const appNavigation = useNavigate();
    const dropdownId = useId();
    const auth = useAuth();

    const { user: { profile: { roles } } } = auth;
    const [showCreateClient, setShowCreateClient] = useState(false);
    const [showCreateMessage, setShowCreateMessage] = useState(false);
    const isAllowedToCreateClients = _.some(roles, i => ["client_create", "sys_admin_access"].includes(i));

    const handleCreateClientClosed = () => setShowCreateClient(false);

    const handleCreateMessageClosed = () => setShowCreateMessage(false);

    const handleOnClientCreated = (contractData) => {
        if (!contractData) {
            return;
        }

        const { masterAccountId } = contractData;
        appNavigation(`/client/${masterAccountId}`);
    };

    const handleOnMessageCreated = (contractData) => {

    }

    return <React.Fragment>
        <CreateClientModal 
            onClientCreated={handleOnClientCreated}
            handleClose={handleCreateClientClosed}
            show={showCreateClient} 
        />
        <CreatePersonalMessageModal
            onCreated={handleOnMessageCreated}
            onHide={handleCreateMessageClosed}
            show={showCreateMessage}
        />
        <NavDropdown id={dropdownId} className="caret-off" align="end" title={(<FontAwesomeIcon icon="fa-plus" size="lg" />)}>
            {isAllowedToCreateClients && (
                <NavDropdown.Item onClick={() => setShowCreateClient(!showCreateClient)}>New Account</NavDropdown.Item>
            )}
            <NavDropdown.Item onClick={() => setShowCreateMessage(!showCreateMessage)}>New Message</NavDropdown.Item>
        </NavDropdown>
    </React.Fragment>
};

export default CreateDropdown;