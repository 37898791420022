import _ from "lodash";
import React from "react";
import { Modal } from "react-bootstrap";
import {
    GenericUserSelect,
    ReportAuthorSelect,
} from "../../../components/forms/selects";
import { Button } from "../../../components";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import { useAuth } from "react-oidc-context";
import { ThemedParagraph } from "../../../components/utilities";
import Moment from "react-moment";
import { Spinner } from "../../../components/loaders";
import toast from "react-hot-toast";

const WorkAssignedLabel = styled(ThemedParagraph)`
    font-size: 10px !important;
    padding-top: 4px;
    float:right;
`;

const ReviewManagementAssignStaffModal = ({
    allowAssignAuthorRoles = ["sys_admin_access", "report_assign_author", "report_assign_self"],
    allowAssignReviewUser = ["sys_admin_access", "review_assign_user"],
    review = null,
    show = false,
    isLoading,
    handleClose = () => {},
    handleSave = () => {},
}) => {

    const { user: { profile }} = useAuth();

    const {
        control,
        errors,
        formState,
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({ defaultValues: review });
    
    const onSubmit = (data) => {
       
        var request = {
            reviewId: review.reviewId,
            assignedToValuationUserId: data.assignedToValuationUserId,
            assignedToDrawdownUserId: data.assignedToDrawdownUserId,
            assignedToPreReviewDocumentUserId: data.preReviewDocumentAuthorId,
            assignedToAppointmentUserId: data.assignedToAppointmentUserId,
            assignedToFollowUpUserId: data.followUpAuthorId,
        }

        toast.promise(handleSave(request), {
            loading: `Saving review Assignments`,
            success: (data) => {
                return `Review Assignments Saved`;
            },
            error: (err) => `Failed to save review assignments`
        });
        
        
    };
    
    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };
    
    React.useEffect(() => {
        if (!review) {
            return;
        }
        reset(review);
    }, [review]);
    
    const isAllowedToAssignAuthor = _.some(profile.roles, item => allowAssignAuthorRoles.includes(item));
    const isAllowedToAssignReviewUser = _.some(profile.roles, item => allowAssignReviewUser.includes(item));

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Assign Review Staff</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <>
                        <div className="row mb-3">
                            <div className="col">
                                <Controller
                                    name="assignedToValuationUserId"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <GenericUserSelect
                                            label="Valuation"
                                            defaultValue={value}
                                            placeholder="No user assigned."
                                            isDisabled={!isAllowedToAssignReviewUser || review?.reviewStatus == 1 || review?.reviewStatus == 2 || formState.isSubmitting || isLoading}
                                            onChange={(values) =>
                                                setValue(
                                                    "assignedToValuationUserId",
                                                    values.value,
                                                    { shouldDirty: true }
                                                )
                                            }
                                            {...rest}
                                        />
                                    )}
                                />
                                {review?.assignedToValuationUserId != null && review?.assignedToValuationUserDate != null && <WorkAssignedLabel>Assigned : <Moment format="DD/MM/YYYY">{review?.assignedToValuationUserDate}</Moment></WorkAssignedLabel>}
                            </div>
                        </div>
                        {review?.isDrawdownReview && 
                        <div className="row mb-3">
                            <div className="col">
                                <Controller
                                    name="assignedToDrawdownUserId"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <GenericUserSelect
                                            label="Drawdown"
                                            defaultValue={value}
                                            placeholder="No user assigned."
                                            isDisabled={!isAllowedToAssignReviewUser || review?.reviewStatus == 1 || review?.reviewStatus == 2 || formState.isSubmitting || isLoading}
                                            onChange={(values) =>
                                                setValue(
                                                    "assignedToDrawdownUserId",
                                                    values.value,
                                                    { shouldDirty: true }
                                                )
                                            }
                                            {...rest}
                                        />
                                    )}
                                />
                                {review?.assignedToValuationUserId != null && review?.assignedToValuationUserDate != null && <WorkAssignedLabel>Assigned : <Moment format="DD/MM/YYYY">{review?.assignedToValuationUserDate}</Moment></WorkAssignedLabel>}
                            </div>
                        </div>}
                        <div className="row mb-3">
                            <div className="col">
                                <Controller
                                    name="preReviewDocumentAuthorId"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <ReportAuthorSelect
                                            label="PRD Author"
                                            defaultValue={value}
                                            disableAnimations={true}
                                            isDisabled={!isAllowedToAssignAuthor || review?.reviewStatus == 1 || review?.reviewStatus == 2 || isLoading}
                                            placeholder="No Author assigned."
                                            onChange={(values) => setValue(
                                                "preReviewDocumentAuthorId",
                                                values.value,
                                                { shouldDirty: true }
                                            )}
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <Controller
                                    name="assignedToAppointmentUserId"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <GenericUserSelect
                                            label="Appointment"
                                            defaultValue={value}
                                            placeholder="No user assigned."
                                            isDisabled={!isAllowedToAssignReviewUser || review?.reviewStatus == 1 || review?.reviewStatus == 2 || isLoading}
                                            onChange={(values) =>
                                                setValue(
                                                    "assignedToAppointmentUserId",
                                                    values.value,
                                                    { shouldDirty: true }
                                                )
                                            }
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <Controller
                                    name="followUpAuthorId"
                                    control={control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...rest
                                        },
                                    }) => (
                                        <ReportAuthorSelect
                                            label="Follow Up Author"
                                            defaultValue={value}
                                            disableAnimations={true}
                                            isDisabled={!isAllowedToAssignAuthor || review?.reviewStatus == 1 || review?.reviewStatus == 2 || isLoading}
                                            placeholder="No Author assigned."
                                            onChange={(values) => setValue(
                                                "followUpAuthorId",
                                                values.value,
                                                { shouldDirty: true }
                                            )}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    {isLoading && <Spinner></Spinner>}
                    <Button variant="success" type="submit" disabled={isLoading}>
                        Save Changes
                    </Button>
                    <Button variant="light" onClick={onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default ReviewManagementAssignStaffModal;