import React from "react";
import Tippy from "@tippyjs/react";
import toast from "react-hot-toast";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, ThemedParagraph } from "../../../components/utilities";
import { useClientSearch } from "../contexts/ClientSearchContext";

export const ResultItem = ({ data, onToggle }) => {
    const appNavigation = useNavigate();
    const { accountReference, accountName, adviser, address, masterAccountId, isFavourited } = data;
    const { addToFavourites, removeFromFavourites } = useClientSearch();

    const handleOnFavouriteChanged = () => {
        if (isFavourited) {
            toast.promise(removeFromFavourites(masterAccountId), {
                loading: `Processing...`,
                success: () => `The client has been removed from your favourites.`,
                error: (err) => {
                    const { message } = err || { message: 'There was an unkown error.' };
                    return message;
                }
            });
        }
        else {
            toast.promise(addToFavourites(masterAccountId), {
                loading: `Processing...`,
                success: () => `The client has been added to your favourites.`,
                error: (err) => {
                    const { message } = err || { message: 'There was an unkown error.' };
                    return message;
                }
            });
        }
    };

    const handleOpenClient = () => {
        // check and toggle the main search dropdown menu
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }

        // push the navigation to open the selected client
        appNavigation(`client/${masterAccountId}`);
    };

    return <div className="row mb-1">
        <div className="col-md-auto">
            <ActionIcon icon="fa-folder-open" onClick={handleOpenClient} />
        </div>
        <div className="col-md-auto font-monospace">{accountReference}</div>
        <div className="col-3">{adviser}</div>
        <div className="col d-flex flex-column">
            <ThemedParagraph>{accountName}</ThemedParagraph>
            <ThemedParagraph variant="muted">{address}</ThemedParagraph>
        </div>
        <div className="col-md-auto">
            <Tippy placement="left" content={(
                <span>{isFavourited ? 'Remove from favourites' : 'Add to favourites'}</span>
            )}>
                <div>
                    <FontAwesomeIcon 
                        onClick={handleOnFavouriteChanged}
                        icon={isFavourited ? 'fa-star' : faStar} 
                        className={classnames('has-pointer', {
                            'text-gold': isFavourited
                        })}
                    />
                </div>
            </Tippy>
        </div>
    </div>
};

const ResultsDisplay = ({ onToggle, ...rest }) => {
    const { data } = useClientSearch();
    const [parent] = useAutoAnimate()
    return <div ref={parent} {...rest}>
        {data.map((el, i) => <ResultItem key={i} data={el} onToggle={onToggle} />)}
    </div>
};

export default ResultsDisplay;