import classNames from "classnames";
import React, { useMemo } from "react";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";
import { FormInput, FormSelect } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { InfinityList } from "../../../components/tables";
import { AccordionSection, Main } from "../../../components/utilities";
import withProviders from "../../../hooks/withProviders";
import ClientEmail from "../../clientemails/components/ClientEmail";
import ClientEmailPreviewRow from "../../clientemails/components/ClientEmailPreviewRow";
import ClientEmailsProvider, { ClientEmailViewTypes, useClientEmails } from "../../clientemails/hooks/ClientEmailsContext";
import useProjectEmails from "../hooks/useProjectEmails";

const GridArea = styled.span`
    grid-area: ${props => props.gridArea};
`

const ProjectEmailHeader = () => {

    const {
        viewType,
        setSearchTerm,
        setViewType
    } = useClientEmails();

    return <div className="d-flex justify-content-between">

        <FormInput
            parentClassName="mb-3"
            disableAnimations
            placeholder="Search..."
            onChange={(e) => setSearchTerm(e.target.value)}
        />

        <FormSelect
            defaultValue={viewType}
            onChange={(selection) => setViewType(selection.value)}
            options={[
                { label: "View Emails", value: ClientEmailViewTypes.Emails },
                { label: "View Conversations", value: ClientEmailViewTypes.Conversations }
            ]}
            disableAnimations
        />
    </div>
}

/**
 * Top level list contains previews of emails/conversations (based on view type)
 * Renders second level containing conversation message previews when selected
 */
const ProjectPreviewList = React.forwardRef(({ projectId, conversationId = null }, ref) => {

    const {
        itemId,
        onItemClick,
        isActive,
        isConversationList,
        results,
        error,
        isError,
        isClearing,
        totalCount,
        clear,
        isItemLoaded,
        loadMoreItems
    } = useProjectEmails({ projectId, conversationId });

    const showCollapse = useMemo(() => isConversationList && itemId != null, [itemId]);

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={clear} />
        </ErrorMessageWrapper>;
    }

    return <>
        <GridArea ref={ref} className={classNames("h-100 border-end")} gridArea={isConversationList ? 'conversations' : 'previews'}>
            <InfinityList
                itemData={{ data: results, onClick: onItemClick, showActive: isActive, isClearing }}
                itemCount={totalCount === results.length && !isClearing ? totalCount : results.length + 15}
                itemSize={96}
                isItemLoaded={isItemLoaded}
                loadMoreItems={loadMoreItems}
                row={ClientEmailPreviewRow}
            />
        </GridArea>
        {conversationId == null && showCollapse &&
            <Collapse in={showCollapse} dimension='width' >
                <ProjectPreviewList
                    projectId={projectId}
                    conversationId={itemId}
                />
            </Collapse>
        }

        {!isConversationList &&
            <GridArea gridArea="email">
                {itemId != null &&
                    <ClientEmail
                        showProjectSelect={false}
                    />
                }
            </GridArea>
        }
    </>
});

const ProjectEmailPreviewLayout = styled(Main)`
    display: grid;
    height: 75vh;
    grid-template-rows: 9fr;
    ${props => props.showConversations
        ? `
            grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) 1fr;
            grid-template-areas:    "conversations previews email";
    `
        : `
            grid-template-columns: minmax(auto, 300px) 4fr;
            grid-template-areas:    "previews email";
    `}
    row-gap: 0;
`;

const ProjectEmails = ({ selectedProject }) => {
    const { projectId } = selectedProject;

    const { showConversations } = useClientEmails();

    return <AccordionSection label="Emails" defaultActiveKey="1">
        <ProjectEmailHeader />

        <ProjectEmailPreviewLayout showConversations={showConversations}>
            <ProjectPreviewList projectId={projectId} />
        </ProjectEmailPreviewLayout>
    </AccordionSection>
}

export default withProviders(ClientEmailsProvider)(ProjectEmails);