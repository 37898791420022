import React, { useContext, useEffect, useState } from "react";
import { wealthProApi } from "../../../redux/api";
import { useAddClientToFavouritesMutation, useRemoveClientFromFavouritesMutation } from "../../../services/clientdetails";

const ClientSearchContext = React.createContext();

export const useClientSearch = () => {
    return useContext(ClientSearchContext);
};

export const ClientSearchProvider = ({ children, timeout = 500, minimumSearchCharacters = 3 }) => {
    // setup state and our query state (does not load by default (lazy))
    const [limit, setLimit] = useState(7);
    const [searchTerm, setSearchTerm] = useState('');
    const [cacheKey, setCacheKey] = React.useState(new Date().valueOf());
    const [isClearing, setIsClearing] = React.useState(false);
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [addToFavouriteMutation] = useAddClientToFavouritesMutation();
    const [removeFromFavouriteMutation] = useRemoveClientFromFavouritesMutation();
    const [load, { data, error, isError, isFetching, isLoading, isUninitialized }] = wealthProApi.endpoints.searchMasterAccounts.useLazyQuery();

    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results } = data || { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };
    const { page, totalCount, totalPages } = pagination;
    const isNextPageLoading = isFetching === true || isLoading === true || isLoadingState === true;

    const addToFavourites = (masterAccountId) => {
        return new Promise((resolve, reject) => {
            return addToFavouriteMutation({ masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const changePage = (pageNo) => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        setIsLoadingState(_ => true);
        load({ 
            page: pageNo, 
            limit, 
            searchTerm,
            cacheKey    
        }).unwrap().then(_ => {
            setIsLoadingState(_prev => false);
        });
    };

    const clear = () => {
        if (isNextPageLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsLoadingState(_ => true);
        setIsClearing(_ => true);
        load({ 
            page: 1, 
            limit, 
            searchTerm,
            cacheKey: newCacheKey 
        }).unwrap().then(_ => {
            setCacheKey(_prev => newCacheKey);
        }).finally(_ => {
            setIsLoadingState(_prev => false);
            setIsClearing(_ => false);
        });
    };

    const handleOnSearchTermChanged = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        // check we have valid input to send
        if (!searchTerm || searchTerm.length < minimumSearchCharacters) {
            return;
        }
        // clear the previous results and begin a new search
        clear();
    };

    const nextPage = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        let newPage = page + 1;
        if (newPage > totalPages) {
            return;
        }
        changePage(newPage);
    };

    const previousPage = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        let newPage = page - 1;
        if (newPage <= 0) {
            return;
        }
        changePage(newPage);
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        clear();
    };

    const removeFromFavourites = (masterAccountId) => {
        return new Promise((resolve, reject) => {
            return removeFromFavouriteMutation({ masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    useEffect(() => {
        const delayedRequest = setTimeout(handleOnSearchTermChanged, timeout);
        return () => clearTimeout(delayedRequest);
    }, [ searchTerm ]);  

    return <ClientSearchContext.Provider value={{ 
        addToFavourites,
        data: isClearing ? [] : results,
        error,
        isError,
        isLoading: isLoading === true || isFetching === true,
        isUninitialized,
        limit,
        minimumCharacters: minimumSearchCharacters,
        nextPage,
        page,
        previousPage,
        reload,
        removeFromFavourites,
        setLimit,
        searchTerm,
        setSearchTerm,
        totalCount,
        totalPages
    }}>
        {children}
    </ClientSearchContext.Provider>
};