import _ from "lodash";
import React from "react";
import { usePatchClientReviewMutation, useAssignReviewUsersMutation } from "../../../services/clientreviews";

const EditReviewContext = React.createContext();

export const useEditReview = () => React.useContext(EditReviewContext);

export const EditReviewProvider = ({ children, showModalOnReviewSet = true}) => {

    const [review, _setReview] = React.useState(null);
    const [showModal, _setShowModal] = React.useState(false);
    const [patch, { isLoading, ...patchResults }] = usePatchClientReviewMutation();
    const [assign, { isLoading: isAssigning }] = useAssignReviewUsersMutation();

    const clear = () => {
        _setReview(_ => null);
        _setShowModal(_ => false);
    };

    const saveChanges = (operations) => {
        const { reviewId, masterAccountId } = review;
        return new Promise((resolve, reject) => {
            return patch({ reviewId: reviewId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const assignUsers = (request) => {
        return new Promise((resolve, reject) => {
            return assign({ request }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const setReview = (review) => {

        _setReview(_ => review);
        if (showModalOnReviewSet === true) {
            _setShowModal(_ => true);
        }
    };

    return (
        <EditReviewContext.Provider value={{ 
            clear,
            isLoading,
            isModalActive: showModal,
            patch: { isLoading, ...patchResults },
            review,
            saveChanges,
            assignUsers,
            isAssigning,
            setReview
        }}>
            {children}
        </EditReviewContext.Provider>
    );
};