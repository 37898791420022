import React, { useId, useState } from "react";
import DropdownWrapper from "./components/DropdownWrapper";
import SearchDropdownMenu from "./components/SearchDropdownMenu";
import SearchDropdownToggle from "./components/SearchDropdownToggle";
import SearchResultsWrapper from "./components/SearchResultsWrapper";
import { Dropdown } from "react-bootstrap";
import { ClientSearchProvider } from "./contexts/ClientSearchContext";

const ClientSearch = () => {
    const dropdownId = useId();
    const dropdownInputId = useId();
    const [isOpen, setIsOpen] = useState(false);

    const handleOnToggle = (state, event) => {
        if (event.source === "toggle") {
            setIsOpen(state);
        }
        else if (event.source === "click" && isOpen === false) {
            setIsOpen(state);
        }
        else if (event.source === "rootClose" && event.originalEvent.target.id !== dropdownInputId) {
            setIsOpen(state);
        }
    }

    const onToggle = (state) => handleOnToggle(state ? state : !isOpen, { source: 'toggle' });

    return <ClientSearchProvider>
        <DropdownWrapper id={dropdownId} show={isOpen} onToggle={handleOnToggle}>
            <Dropdown.Toggle 
                id={dropdownInputId} 
                as={SearchDropdownToggle} 
                show={isOpen}
                onToggle={(value) => setIsOpen(_ => value)}
            />
            <Dropdown.Menu
                as={SearchDropdownMenu}
                show={isOpen}
                onToggle={onToggle}
            >
                <SearchResultsWrapper onToggle={onToggle} />
            </Dropdown.Menu>
        </DropdownWrapper>
    </ClientSearchProvider>
};

export default ClientSearch;