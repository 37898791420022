import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useState } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Tabs, TabScreen } from "react-tabs-scrollable";
import { Button } from "../../../components";
import { NavigationContainer } from "../../../components/navigation";
import { NavigationTab } from "../../../components/navigation/NavigationTabs";
import { NewMoneyStatuses } from "../hooks/useAdminNewMoney";
import useNewMoneyFormTitle from "../hooks/useNewMoneyFormTitle";
import NewMoneyForm from "./NewMoneyForm";
import NewMoneyLogs from "./NewMoneyLogs";

export const NewMoneyEditModal = ({ show, onHide, save, isSaving, existingNewMoney, disabled = false }) => {
    const defaultValues = { ...existingNewMoney, overwriteComment: "" };

    const form = useForm({ defaultValues });
    const { watch, reset, resetField, handleSubmit, formState: { dirtyFields } } = form;

    const title = useNewMoneyFormTitle(watch);

    const isDirty = _.some(dirtyFields);

    const showOverwriteComment = existingNewMoney.status !== NewMoneyStatuses.Pending && isDirty;

    const onClose = () => {
        reset(existingNewMoney);
        onHide();
    }

    const onSubmit = (data) => {
        const { overwriteComment, ...newMoney } = data;
        save(newMoney, overwriteComment)
            .then(() => resetField("overwriteComment"));
    }

    const [activeTab, setActiveTab] = useState(0);
    const onTabClick = (e, i) => {
        e.preventDefault();
        setActiveTab(i);
    }

    return <Modal className="overflow-visible" size="xl" show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header closeButton>
            <div>
                <h4>Edit New Money</h4>
                <h5 className="mb-0">{title}</h5>
            </div>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body className="d-flex flex-column p-0">
                {existingNewMoney.status !== NewMoneyStatuses.Pending && <NavigationContainer className="p-2 pb-0">
                    <Tabs
                        activeTab={activeTab}
                        onTabClick={onTabClick}
                        leftBtnIcon={<FontAwesomeIcon icon="fa-chevron-left" />}
                        rightBtnIcon={<FontAwesomeIcon icon="fa-chevron-right" />}
                    >
                        <NavigationTab data={{ icon: "fa-pencil", label: "Edit" }} />
                        <NavigationTab data={{ icon: "fa-list", label: "Logs" }} />
                    </Tabs>
                </NavigationContainer>}
                <TabScreen activeTab={activeTab} index={0} className="p-3">
                    <NewMoneyForm form={form} showOverwriteComment={showOverwriteComment} disabled={disabled} />
                </TabScreen>
                <TabScreen activeTab={activeTab} index={1} className="overflow-auto p-3">
                    <NewMoneyLogs newMoneyId={existingNewMoney.newMoneyId} />
                </TabScreen>
            </Modal.Body>
            {activeTab === 0 && <Modal.Footer>
                <ButtonGroup>
                    <Button variant="danger" onClick={onClose} disabled={disabled || isSaving}>Cancel Changes</Button>
                    <Button variant="success" type='submit' disabled={disabled || isSaving}>Save Changes</Button>
                </ButtonGroup>
            </Modal.Footer>}
        </form>
    </Modal>
}