import { Modal } from "react-bootstrap"
import { Button } from "../../../components"
import { usePricesManagement } from "../hooks/PricesManagementContext";
import { useMemo } from "react";
import { DateInput, NumberInput } from "../../../components/forms";

const EditPriceModal = () => {

    const { priceToEdit, patchPrice, setPriceToEdit } = usePricesManagement();
    const show = useMemo(() => priceToEdit ? true : false, [priceToEdit]);

    const {
        priceDate,
        midPrice,
        bidPrice,
        offerPrice
    } = priceToEdit || {};

    const onClose = () => {
        setPriceToEdit(null);
    };

    const handlePatch = (property, newValue) => patchPrice(priceDate, property, newValue);

    return <Modal size="lg" backdrop="static" centered show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col">
                    <DateInput
                        value={priceDate}
                        decimalScale={4}
                        label="Price Date"
                        onBlur={(value) => handlePatch("priceDate", value)}
                    />
                </div>
                <div className="col">
                    <NumberInput
                        value={bidPrice}
                        decimalScale={4}
                        label="Bid Price"
                        onBlur={(value) => handlePatch("bidPrice", value)}
                    />
                </div>
                <div className="col">
                    <NumberInput
                        value={midPrice}
                        decimalScale={4}
                        label="Mid Price"
                        onBlur={(value) => handlePatch("midPrice", value)}
                    />
                </div>
                <div className="col">
                    <NumberInput
                        value={offerPrice}
                        decimalScale={4}
                        label="Offer Price"
                        onBlur={(value) => handlePatch("offerPrice", value)}
                    />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="light" onClick={onClose}>Close</Button>
        </Modal.Footer>
    </Modal>
};

export default EditPriceModal;