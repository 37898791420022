import React, { useEffect, useState } from "react";
import AssetsManagementProvider, { useAssetsManagement } from "./hooks/AssetsManagementContext";
import AssetsTable from "./components/AssetsTable";
import { FiltersAppliedWarningMessage, Main, PageSubHeader, ThemedIcon } from "../../components/utilities";
import { Button } from "../../components";
import AssetTableFilterModal from "./components/AssetTableFilterModal";
import toast from "react-hot-toast";
import { ConfirmModal } from "../../components/modals";
import EditAssetModal from "./components/EditAssetModal";
import { useFilterContext } from "../../hooks/FilterContext";

const AssetsManagementHeader = () => {
    const [isFiltersShown, setIsFiltersShown] = useState();
    const { defaultFilters, filter, setFilter,isFiltersApplied } = useFilterContext();

    
    const {
        createAsset,
        createResult,
        assetToDelete,
        setAssetIdToDelete,
        deleteSelectedAsset
    } = useAssetsManagement();

    const onCreate = (e) => {
        e.preventDefault();

        toast.promise(createAsset(), {
            loading: "Creating asset...",
            error: "Unable to create asset.",
            success: "Asset created!"
        });
    };

    const onClearFilters = () => {
        setFilter((_) => defaultFilters);
    };  

    // Show delete confirmation modal when assetIdToDelete is set, then reset it to null *after* the modal is closed
    // Prevents "this unnamed asset" appearing for named assets
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        if (assetToDelete) {
            setShowDeleteModal(true);
        }
    }, [assetToDelete]);

    useEffect(() => {
        if (!showDeleteModal) {
            const timeout = setTimeout(() => setAssetIdToDelete(null), 500);
            return () => clearTimeout(timeout);
        }
    }, [setAssetIdToDelete, showDeleteModal]);

    const onDelete = () => {
        setShowDeleteModal(false);
        toast.promise(deleteSelectedAsset(), {
            loading: "Deleting asset...",
            error: "Unable to delete asset.",
            success: "Asset deleted!"

        }).finally(() => setAssetIdToDelete(null));
    };

    return (
        <>
            <PageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being applied to your results.
                                    <br />
                                    <button className="anchor" onClick={onClearFilters}>Click here</button> to clear your filters.
                                </FiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex flex-fill flex-row-reverse">
                        <Button className="ms-3" onClick={() => setIsFiltersShown(true)}>
                            Filter Assets
                        </Button>

                        <Button
                            onClick={onCreate}
                            disabled={createResult.isLoading}
                            variant="success"
                            className="d-flex align-items-center"
                        >
                            <ThemedIcon variant="light" className="me-1" icon="plus" />
                            Create New Asset
                        </Button>
                    </div>
                </div>
            </PageSubHeader>
            <AssetTableFilterModal
                show={isFiltersShown}
                onHide={() => setIsFiltersShown(null)}
            />
            <ConfirmModal
                title={"Delete Asset"}
                message={`Are you sure you want to delete ${assetToDelete?.assetName ?? "this unnamed asset"}?`}
                handleClose={() => setShowDeleteModal(false)}
                handleConfirm={onDelete}
                confirmButtonLabel="Delete"
                confirmButtonVariant="danger"
                show={showDeleteModal}
            />
            <EditAssetModal />
        </>
    )
};

const AssetsManagement = () => {

    return (
            <Main className="d-flex flex-column">
                <AssetsManagementHeader />
                <section className="px-4 my-3 h-100">
                    <AssetsTable />
                </section>
            </Main>
    )
};

export default AssetsManagement;