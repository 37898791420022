import React from "react";
import ProjectDetails from "./components/ProjectDetails";
import ProjectDocumentsList from "./components/ProjectDocumentsList";
import ProjectReminders from "./components/ProjectReminders";
// import ProjectReportsList from "./components/ProjectReportsList";
import { ClientLayoutPageSubHeader, Main, ThemedParagraph } from "../../components/utilities";
import { useClientProjectContext } from "../../hooks/ClientProjectContext";
import ClientProjectSearch from "../clientprojectsearch";
import ClientProjectsReportsTable from "../clientprojectsreportstable";
import ProjectEmails from "./components/ProjectEmails";
import ProjectMessagesList from "./components/ProjectMessagesList";

const ClientProjects = () => {
    const { selectedValue: selectedProject, setSelectedValue: setSelectedProject } = useClientProjectContext();

    const handleOnProjectCreated = (project, autoSelect) => {
        if (autoSelect === true) {
            setSelectedProject(project);
        }
    };

    const handleOnProjectSelected = (_, project) => setSelectedProject(project);

    return <React.Fragment>
        <ClientLayoutPageSubHeader>
            <ClientProjectSearch 
                value={selectedProject ? selectedProject.projectId : null}
                onProjectCreated={handleOnProjectCreated}
                onProjectSelected={handleOnProjectSelected}
            />
        </ClientLayoutPageSubHeader>
        {selectedProject && (
            <Main className="p-3">
                <ProjectDetails 
                    selectedProject={selectedProject} 
                />
                {/* <ProjectReportsList 
                    selectedProject={selectedProject}
                    onProjectChanged={(projectId) => setSelectedProject({ projectId })}
                /> */}
                <ClientProjectsReportsTable />
                <ProjectMessagesList selectedProject={selectedProject} />
                <ProjectEmails selectedProject={selectedProject} />
                <ProjectReminders 
                    selectedProject={selectedProject}
                />
                <ProjectDocumentsList 
                    selectedProject={selectedProject}
                />
            </Main>
        )}
        {!selectedProject && (
            <Main className="p-3">
                <ThemedParagraph variant="muted">Please select or create a new project.</ThemedParagraph>
            </Main>
        )}
    </React.Fragment>
};

export default ClientProjects;