import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTheme } from "styled-components";
import { ConfirmModal } from "../../../components/modals";
import { NewMoneyStatuses, useAdminNewMoney } from "../hooks/useAdminNewMoney";
import { NewMoneyEditModal } from "./NewMoneyEditModal";

const NewMoneyActionToggle = React.forwardRef(({ onClick }, ref) => (
    <FontAwesomeIcon
        ref={ref}
        className="has-pointer"
        icon="fa-ellipsis"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    />
));

export const NewMoneyEditCellComponent = ({ row }) => {
    const { newMoneyId, status } = row;
    const theme = useTheme();

    const {
        approveNewMoney, cancelNewMoney, saveNewMoney, canWrite, isLoading, isSaving
    } = useAdminNewMoney();

    const [showEditModal, setShowEditModal] = useState(false);

    const [newStatus, setNewStatus] = useState(null);
    const isApproving = useMemo(() => newStatus === NewMoneyStatuses.Approved, [newStatus]);

    const confirm = () => {
        if (isApproving)
            approveNewMoney(newMoneyId);
        else
            cancelNewMoney(newMoneyId);

        setNewStatus(null);
    }

    const save = (newMoney, overwriteComment) => saveNewMoney(newMoneyId, newMoney, overwriteComment);

    return <td className={classNames("text-center align-middle", { "d-none": !canWrite })}>
        <NewMoneyEditModal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            save={save}
            isSaving={isSaving}
            existingNewMoney={row}
            disabled={isLoading}
        />
        <ConfirmModal
            show={newStatus !== null}
            theme={theme}
            size="md"
            isUpdating={isSaving}
            title="Are you sure?"
            handleConfirm={confirm}
            handleClose={() => setNewStatus(null)}
            confirmButtonVariant={isApproving ? "success" : "danger"}
        >
            Are you sure you want to <strong>{isApproving ? "approve" : "abort"}</strong> this new money?
        </ConfirmModal>
        {/* Show dropdown with status change ability if pending, otherwise edit button */}
        {status === NewMoneyStatuses.Pending
            ? <Dropdown className="caret-off w-100">
                <Dropdown.Toggle as={NewMoneyActionToggle} />
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setShowEditModal(true)}>
                        Edit New Money
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => setNewStatus(NewMoneyStatuses.Approved)}>Approve New Money</Dropdown.Item>
                    <Dropdown.Item onClick={() => setNewStatus(NewMoneyStatuses.Aborted)}>Mark as Not Taken Up</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            : <FontAwesomeIcon
                onClick={() => setShowEditModal(true)}
                icon="pen-to-square"
                className="has-pointer"
                size="lg" />}
    </td>;
};