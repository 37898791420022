import { useEffect, useMemo, useState } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchMasterAccountEmailConversationMessagePreviewsQuery, useLazyFetchMasterAccountEmailConversationPreviewsQuery, useLazyFetchMasterAccountEmailPreviewsQuery } from "../../../services/useremails";
import { useClientEmails } from "../../clientemails/hooks/ClientEmailsContext";

const limit = 50;

const useProjectEmails = ({ projectId, conversationId: selectedConversationId }) => {
    const { id: masterAccountId } = useClientContext();

    const {
        // attachmentsOnly,
        conversationId,
        mailId,
        searchTerm,
        showConversations,
        // viewType,
        // setAttachmentsOnly,
        setConversationId,
        setMailId,
        // setSearchTerm,
        // setViewType
    } = useClientEmails();

    const [fetchConversations, conversationResult] = useLazyFetchMasterAccountEmailConversationPreviewsQuery();
    const [fetchEmailPreviews, emailPreviewResult] = useLazyFetchMasterAccountEmailPreviewsQuery();
    const [fetchConversationEmailPreviews, conversationEmailPreviewResult] = useLazyFetchMasterAccountEmailConversationMessagePreviewsQuery();

    const onEmailClick = ({ graphId }) => setMailId(graphId);
    const onConversationClick = ({ conversationId }) => setConversationId(conversationId);

    const isEmailActive = ({ graphId }) => itemId === graphId;
    const isConversationActive = ({ conversationId }) => itemId === conversationId;

    const [[fetch, { data, isError, error, isUninitialized }], [itemId, onItemClick], isActive] =
        showConversations
            ? selectedConversationId == null
                ? [
                    [fetchConversations, conversationResult],
                    [conversationId, onConversationClick],
                    isConversationActive
                ]
                : [
                    [fetchConversationEmailPreviews, conversationEmailPreviewResult],
                    [mailId, onEmailClick],
                    isEmailActive
                ]
            : [
                [fetchEmailPreviews, emailPreviewResult],
                [mailId, onEmailClick],
                isEmailActive
            ];

    const { results, pagination } = data || { results: [], pagination: { totalCount: 50, page: 0 } };
    const { totalCount, page } = pagination;

    const [isClearing, setIsClearing] = useState(false);

    const clear = (e) => {
        setIsClearing(true);
        if (typeof (e?.preventDefault) === "function")
            e.preventDefault();

        fetch({
            page: 1,
            limit,
            masterAccountId,
            projectId,
            searchTerm,
            conversationId: selectedConversationId
        }).finally(_ => setIsClearing(false));
    }

    const isItemLoaded = (index) => {
        return results[index] || index < results.length
    }

    const loadMoreItems = () => fetch({
        page: page + 1,
        limit,
        masterAccountId,
        projectId,
        searchTerm,
        conversationId: selectedConversationId
    });

    useEffect(() => {
        !isUninitialized && clear();
    }, [searchTerm, selectedConversationId]);

    const isConversationList = useMemo(() => showConversations && selectedConversationId == null, [selectedConversationId, showConversations]);

    return {
        itemId,
        onItemClick,
        isActive,
        isConversationList,
        results,
        error,
        isError,
        isClearing,
        totalCount,
        clear,
        isItemLoaded,
        loadMoreItems
    };
}

export default useProjectEmails;