import { Modal } from "react-bootstrap";
import { useAssetsManagement } from "../hooks/AssetsManagementContext";
import { useMemo } from "react";
import { Button } from "../../../components";
import { DateInput, FormInput, FormSelect, NumberInput, FormCheck } from "../../../components/forms";
import ProviderPagedSelect from "../../../components/forms/selects/ProviderPagedSelect";
import { CenterFormColumnComponent } from "../../../components/utilities";

const EditAssetModal = () => {

    const { assetToEdit, setAssetIdToEdit, patchSelectedAsset } = useAssetsManagement();
    const show = useMemo(() => assetToEdit !== null, [assetToEdit]);

    const handleHide = () => {
        setAssetIdToEdit(null);
    }

    const {
        assetName,
        longAssetName,
        providerId,
        providerName,
        finexFundCode,
        citicode,
        sedol,
        sectorCode,
        incomeOrAccumulation,
        finexSubcategory,
        currency,
        multiplier,
        midPrice,
        bidPrice,
        offerPrice,
        priceDate,
        priceIndex
    } = assetToEdit || {};

    return (
        <Modal centered size="lg" backdrop="static" show={show} onHide={handleHide}>
            <Modal.Header>
                <Modal.Title>{longAssetName}</Modal.Title>

            </Modal.Header>

            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <FormInput
                            label="Asset Name"
                            value={assetName}
                            onBlur={(value) => patchSelectedAsset("assetName", value)}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Long Asset Name"
                            value={longAssetName}
                            onBlur={(value) => patchSelectedAsset("longAssetName", value)}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <ProviderPagedSelect
                            label="Provider"
                            value={{ value: providerId, label: providerName }}
                            setValue={({ value, label }) => patchSelectedAsset("providerId", value, { providerName: label })}
                        />
                    </div>
                    <div className="col d-flex">
                        <FormInput
                            className="me-4"
                            label="Fund Code"
                            value={finexFundCode}
                            onBlur={(value) => patchSelectedAsset("finexFundCode", value)}
                        />
                        <FormInput
                            label="Citicode"
                            value={citicode}
                            onBlur={(value) => patchSelectedAsset("citicode", value)}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <FormInput
                            label="Sedol"
                            value={sedol}
                            onBlur={(value) => patchSelectedAsset("sedol", value)}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Sector Code"
                            value={sectorCode}
                            onBlur={(value) => patchSelectedAsset("sectorCode", value)}
                        />
                    </div>
                    <div className="col">
                        <FormSelect
                            label="Income or Accumulation"
                            defaultValue={incomeOrAccumulation}
                            options={[
                                {
                                    label: "Income",
                                    value: 0
                                },
                                {
                                    label: "Accumulation",
                                    value: 1
                                },
                                {
                                    label: "Unset",
                                    value: 2
                                }
                            ]}
                            onBlur={({ value }) => patchSelectedAsset("incomeOrAccumulation", value)}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Finex Subcategory"
                            value={finexSubcategory}
                            onBlur={(value) => patchSelectedAsset("finexSubcategory", value)}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-3">
                        <FormInput
                            label="Currency"
                            value={currency}
                            onBlur={(value) => patchSelectedAsset("currency", value)}
                        />
                    </div>
                    <div className="col-3">
                        <NumberInput
                            label="Multiplier"
                            isAllowed={(values) => {
                                return values.value <= 1;
                            }}
                            value={multiplier}
                            onBlur={(value) => patchSelectedAsset("multiplier", value)}
                        />
                    </div>
                    <div className="col-3">
                        <DateInput
                            label="Price Date"
                            value={priceDate}
                            onBlur={(value) => patchSelectedAsset("priceDate", value)}
                        />
                    </div>
                    <div className="col-3">
                        <CenterFormColumnComponent>
                            <FormCheck
                                onChange={() => patchSelectedAsset("priceIndex", !priceIndex)}
                                label={"Priced"}
                                isChecked={priceIndex}
                                />
                        </CenterFormColumnComponent>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <NumberInput
                            label="Mid Price"
                            value={midPrice}
                            decimalScale={4}
                            onBlur={(value) => patchSelectedAsset("midPrice", value)}
                        />
                    </div>
                    <div className="col">
                        <NumberInput
                            label="Bid Price"
                            value={bidPrice}
                            decimalScale={4}
                            onBlur={(value) => patchSelectedAsset("bidPrice", value)}
                        />
                    </div>
                    <div className="col">
                        <NumberInput
                            label="Offer Price"
                            value={offerPrice}
                            decimalScale={4}
                            onBlur={(value) => patchSelectedAsset("offerPrice", value)}
                        />
                    </div>
                </div>
                
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={handleHide} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditAssetModal;